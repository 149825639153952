<template>
  <div class="portfolio-swiper">
    <div class="arrow-btn">
        <div class="custom-button-prev custom-btn">
            <div class="triangle triangle-prev"></div>
        </div>
        <div class="custom-button-next custom-btn">
            <div class="triangle triangle-next"></div>
        </div>
    </div>
    <swiper
     v-if="pfData.length > 0"
      :slides-per-view="2"
      :space-between="40"
      :loop="true"
      :speed="1000"
      :autoplay='{
        "delay": 3000,
        "disableOnInteraction": false
        }'
        :navigation="{
            nextEl: '.custom-button-next',
            prevEl: '.custom-button-prev',
        }"
    >
    <!-- <swiper
    v-if="pfData.length > 0"
      :slides-per-view="2"
      :space-between="40"
      :loop="true"
      :speed="1500"
      :navigation="{
          nextEl: '.custom-button-next',
          prevEl: '.custom-button-prev',
        }"
    > -->
    <!-- 포폴 페이지로 넘어가는거 있어야 함 -->
      <swiper-slide v-for="(item, index) in pfData" :key="index" class="pf-wrap">
        <div class="contents-box">
            <div class="img-wrap">
              <img :src="item.imgLocation" alt="">    
            </div>
            <router-link :to="`/portfolio/${item.id}`" class="title">{{ item.title }}</router-link>
            <!-- <p class="title">{{ item.title }}</p> -->
        </div>
      </swiper-slide>
    </swiper>

  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import {Autoplay} from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Autoplay])

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperOptions: {
                pagination: {
                el: '.swiper-pagination',
                    clickable: true,
                },
            },
            pfData: [],
        };
    },
    computed: {
        // pfData() {
        //     return this.getPost();
        // }
    },
    mounted() {
        this.getPost();
    },
    methods: {
        onSlideChange () {
            // console.log('slide change')
        },
            // 게시글 목록 불러오기
        getPost() {
            this.$server.get('/getPost/portfolio')
                .then(res => {
                    console.log(res.data);
                    this.pfData = res.data;
                })
                .catch(err => {
                    console.log(err);
                })
        },
    }
}
</script>

<style scoped>
.portfolio-swiper {
    /* outline: 1px solid blue; */
    width: 100%;
    height: 480px;
    position: relative;
}
.swiper-container {
    /* outline: 1px solid blue; */
    width: 1040px;
    height: 100%;
    margin: 0 auto;
}
.swiper-slide {
    position: relative;
  /* text-align: center; */
  font-size: 18px;
  /* background: #fff; */
  /* overflow: hidden; */
}
.pf-wrap:nth-child(odd) .contents-box {
    position: absolute;
    bottom: 0;
}
.pf-wrap a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    text-align: left;
    font-size: 24px;
    padding: 0 10px;
    color: #777;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.contents-box {
    width: 500px;
}
.contents-box .img-wrap {
    width: 500px;
    height: 340px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 15px;
}
.contents-box .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.arrow-btn {
    /* outline: 1px solid green; */
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.arrow-btn .custom-btn {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/* 만약 loop안하면 끝일때 화살표 배경 연하게 */
.arrow-btn .custom-btn.swiper-button-disabled {
    background-color: #ccc;
}
.arrow-btn .custom-btn .triangle {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.arrow-btn .custom-btn .triangle-prev {
    border-right: 17px solid #fff;
    margin-right: 5px;
}
.arrow-btn .custom-btn .triangle-next {
    border-left: 17px solid #fff;
    margin-left: 5px;
}
/* 태블릿 */
@media screen and (max-width: 1023px) {

}

/* 모바일 */
@media screen and (max-width: 767px) {
    .portfolio-swiper {
        /* overflow: hidden; */
        height: 350px;
    }
    .swiper-container {
        /* outline: 1px solid green; */
        width: 100%;
        min-width: 600px;
        max-width: 620px;
        height: 300px;
    }
    .contents-box {
        /* outline: 1px solid blue; */
        width: 110%;
        max-width: 290px;
    }
    .contents-box .img-wrap {
        width: 100%;
        height: 200px;
        border-radius: 20px;
        overflow: hidden;
    }
    .contents-box .title {
        font-size: 20px;
    }
    .arrow-btn {
        position: relative;
        top: 0;
        transform: initial;
        justify-content: initial;
        gap: 10px;
    }
    .arrow-btn .custom-btn {
        width: 40px;
        height: 40px;
    }
    .arrow-btn .custom-btn .triangle {
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }
    .arrow-btn .custom-btn .triangle-prev {
        border-right: 14px solid #fff;
        margin-right: 3px;
    }
    .arrow-btn .custom-btn .triangle-next {
        border-left: 14px solid #fff;
        margin-left: 3px;
    }
}
</style>