/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";

import { common } from "./common";
import { auth } from "./AuthModule";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  //외부 자바스크립트(xxx.js)를 정의해서 쓰는 곳
  modules: {
    common,
    auth,
  },
  plugins: [
    createPersistedState({
      paths: ["save"],
    }),
  ],
});
