<template>
  <div class="main-swiper">
    <swiper
      :slides-per-view="1"
      :loop="true"
      :speed="1500"
      :autoplay='{
        "delay": 5000,
        "disableOnInteraction": false
        }'
        :pagination='{"clickable": true}'
      @slideChange="onSlideChange"
    >
    <!-- <swiper
      :slides-per-view="1"
      :loop="true"
      :speed="1500"
        :pagination='{"clickable": true}'> -->
      <swiper-slide class="banner1">
          <div class="txt-wrap">
              <h2><span>나보로스</span>
                  <br>
                  드론 안전제어장치</h2>
              <p>사람들과의 공존을 보장하는 안전비행의 필수품</p>
            </div>
      </swiper-slide>
      <swiper-slide class="banner2">
          <div class="txt-wrap">
              <h2>과학기술 서비스업과
                  <br>
                  제조업 전문기업</h2>
              <p>PCB설계/제작, SMT,  부품 소싱 등 원스톱 서비스 제공</p>
          </div>
      </swiper-slide>
      <swiper-slide class="banner3">
          <div class="txt-wrap">
              <h2>과학기술 서비스업과
                <br>
                제조업 전문기업</h2>
              <p>사람들과의 공존을 보장하는 안전비행의 필수품</p>
          </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import {Autoplay} from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Autoplay])

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperOptions: {
                pagination: {
                el: '.swiper-pagination',
                    clickable: true,
                },
            },
        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        onSlideChange () {
            // console.log('slide change')
        }
    }
}
</script>

<style scoped>
.main-swiper {
    width: 100%;
    height: calc(100vh - 80px);
    position: relative;
}
.swiper-container {
    width: 100%;
    height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.banner1 {
    background-image: url('../assets/img/bg_1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.banner2 {
    background-image: url('../assets/img/bg_2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.banner3 {
    background-image: url('../assets/img/bg_3.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.txt-wrap {
    width: 1200px;
    text-align: left;
    color: #fff;
}
.txt-wrap h2 {
    font-size: 50px;
    margin-bottom: 25px;
    line-height: 55px;
}
.txt-wrap h2 span {
    font-size: 50px;
    font-weight: 300;
}
.txt-wrap p {
    font-size: 24px;
}
/* 태블릿 */
@media screen and (max-width: 1023px) {

}

/* 모바일 */
@media screen and (max-width: 767px) {
    .main-swiper {
        height: calc(100vh - 50px);
    }
    .txt-wrap {
        /* outline: 1px solid yellow; */
        width: 90%;
    }
    .txt-wrap h2 {
        font-size: 36px;
        margin-bottom: 16px;
        line-height: 42px;
    }
    .txt-wrap h2 span {
        font-size: 36px;
    }
    .txt-wrap p {
        font-size: 16px;
        line-height: 22px;
        word-break: keep-all;
    }
    .banner1 {
        background-position-x: 60%;
    }
}
</style>