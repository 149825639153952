var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"portfolio-swiper"},[_vm._m(0),(_vm.pfData.length > 0)?_c('swiper',{attrs:{"slides-per-view":2,"space-between":40,"loop":true,"speed":1000,"autoplay":{
      "delay": 3000,
      "disableOnInteraction": false
      },"navigation":{
          nextEl: '.custom-button-next',
          prevEl: '.custom-button-prev',
      }}},_vm._l((_vm.pfData),function(item,index){return _c('swiper-slide',{key:index,staticClass:"pf-wrap"},[_c('div',{staticClass:"contents-box"},[_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":item.imgLocation,"alt":""}})]),_c('router-link',{staticClass:"title",attrs:{"to":`/portfolio/${item.id}`}},[_vm._v(_vm._s(item.title))])],1)])}),1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-btn"},[_c('div',{staticClass:"custom-button-prev custom-btn"},[_c('div',{staticClass:"triangle triangle-prev"})]),_c('div',{staticClass:"custom-button-next custom-btn"},[_c('div',{staticClass:"triangle triangle-next"})])])
}]

export { render, staticRenderFns }