export const common = {
  namespaced: true,
  state: {
    post: {},
  },
  mutations: {
    SET_POST(state, post) {
      state.post = post;
    },
  },
};
